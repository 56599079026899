import { writable } from 'svelte/store';
import { debugLog } from '$lib/utils';

const { subscribe, set, update } = writable(false);

export const darkMode = {
	subscribe,
	enableDarkMode: () => set(true),
	disableDarkMode: () => set(false),
	toggleDarkMode: () => {
		debugLog('toggling dark mode');
		update((value) => !value);
	},
	set
};
