<script lang="ts">
	import { onMount } from 'svelte';
	import { darkMode } from '$stores/darkMode';
	import * as Sentry from '@sentry/browser';
	import { BrowserTracing } from '@sentry/tracing';
	import RootLayout from '$lib/RootLayout.svelte';

	onMount(() => {
		const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
		// darkMode.set(prefersDarkMode);
		if (!import.meta.env.DEV) {
			console.log('🔍 Initializing Sentry for error debugging purposes only 🔍');
			Sentry.init({
				dsn: 'https://d1733abdec134f9081b6f77032840722@o358776.ingest.sentry.io/6518538',
				integrations: [new BrowserTracing()],
				// https://docs.sentry.io/platforms/javascript/configuration/sampling/
				tracesSampleRate: 0.5
			});
		}
	});
</script>

<svelte:head>
	<!-- SMUI Styles -->
	{#if $darkMode === undefined}
		<link rel="stylesheet" href="/smui.css" media="(prefers-color-scheme: light)" />
		<link rel="stylesheet" href="/smui-dark.css" media="screen and (prefers-color-scheme: dark)" />
	{:else if $darkMode}
		<link rel="stylesheet" href="/smui.css" />
		<link rel="stylesheet" href="/smui-dark.css" media="screen" />
	{:else}
		<link rel="stylesheet" href="/smui.css" />
	{/if}
</svelte:head>

<RootLayout>
	<slot />
</RootLayout>
