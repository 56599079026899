<script lang="ts">
	import { onMount, createEventDispatcher } from 'svelte';
	import { goto } from '$app/navigation';
	import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';

	import Button from '@smui/button';
	import SvgIcon from './SvgIcon.svelte';
	import userStore from '$stores/userStore';
	import { demoMeals, upsertMeal } from '$db/mealDao';
	import { waitForElement, debugLog, DEFAULT_TOUR_OPTIONS, isDemoUser } from './utils';
	import type { UserData } from '../types';
	import type Tour from 'shepherd.js/../types/tour';
	import auth from '$stores/authStore';

	export let user: UserData | null;

	async function addDemoMeals() {
		const placeId = $userStore?.user?.placeId || '';
		for (const meal of demoMeals) {
			await upsertMeal(placeId, meal);
		}
		debugLog('Demo meals added');
	}
	let isDemo = false;
	let tour: Tour;
	const dispatch = createEventDispatcher();

	onMount(async () => {
		isDemo = isDemoUser(user);

		if (!isDemo) return;

		const { default: Shepherd } = await import('shepherd.js');
		debugLog('Creating shepherd tour');

		tour = new Shepherd.Tour({
			useModalOverlay: true,
			exitOnEsc: true,
			confirmCancel: true,
			confirmCancelMessage: 'Are you sure you want to cancel the demo?',
			defaultStepOptions: {
				classes: 'shadow-md bg-purple-dark',
				scrollTo: true,
				...DEFAULT_TOUR_OPTIONS
			}
		});

		tour.addStep({
			id: 'meal-tab-step',
			text: 'Click the Meals tab to add some meals to your plan.',
			attachTo: {
				element: '#tab-meals',
				on: 'bottom'
			},
			advanceOn: { selector: '#tab-meals', event: 'click' },
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					waitForElement('#tab-meals').then(() => {
						resolve(true);
					});
				});
			},
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
			}
		});
		tour.addStep({
			id: 'add-meal-step',
			text: 'Click the Add Meal button to add your first meal.',
			attachTo: {
				element: '#add-meal-button',
				on: 'bottom'
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					waitForElement('#add-meal-button').then(() => {
						debugLog('Found #add-meal-button');
						resolve(true);
					});
				});
			},
			advanceOn: { selector: '#add-meal-button', event: 'click' },
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
			}
		});
		tour.addStep({
			id: 'add-meal-name-step',
			text: 'Enter a name for your meal.',
			attachTo: {
				element: '#new-meal-name',
				on: 'right-start'
			},
			when: {
				show: function () {
					debugLog('setting focus to add-meal-name-step');
					setTimeout(() => {
						window.postMessage({ type: 'shepherdEvent', mealName: 'My Favorite Meal' }, '*');
					}, 400);
				}
			},
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 65] } }]
			},
			buttons: [
				{
					text: 'Next',
					action: tour.next
				}
			]
		});

		tour.addStep({
			id: 'add-meal-ingredient-step',
			text: 'Enter at least one ingredient for your meal. Use the plus button or hit Enter to add more ingredients.',
			attachTo: {
				element: '.ingredients-grid',
				on: 'left-start'
			},
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
			},
			when: {
				show: function () {
					setTimeout(() => {
						window.postMessage(
							{
								type: 'shepherdEvent',
								newIngredients: [
									{
										id: '1',
										product: 'Ramen',
										amount: '1 pouch'
									}
								]
							},
							'*'
						);
					}, 400);
				}
			},
			buttons: [
				{
					text: 'Next',
					action: tour.next
				}
			]
		});

		tour.addStep({
			id: 'save-new-meal-step',
			text: 'Click add to finish adding your new meal',
			attachTo: {
				element: '#save-new-meal-button',
				on: 'right-start'
			},
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 40] } }]
			},
			advanceOn: { selector: '#save-new-meal-button', event: 'click' }
		});

		tour.addStep({
			id: 'add-more-step',
			text: "Let's quickly add a few more sample meals to make things more realistic.",
			buttons: [
				{
					text: 'Add more meals',
					action: () => {
						debugLog('in addmorestep');
						addDemoMeals();
						return tour.next();
					}
				}
			]
		});

		tour.addStep({
			id: 'meal-plans-tab-step',
			text: 'Click the Meal Plans tab to plan your meals.',
			attachTo: {
				element: '#tab-plans',
				on: 'bottom'
			},
			advanceOn: { selector: '#tab-plans', event: 'click' },
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
			}
		});

		tour.addStep({
			id: 'select-meal-day1-step',
			text: 'Select a meal to prepare on Sunday.  Notice that the Shopping List will be populated with the ingredients for that meal.',
			attachTo: {
				element: '.meal',
				on: 'right-start'
			},
			advanceOn: { selector: '.daymeal', event: 'change' },
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
			}
		});

		tour.addStep({
			id: 'click-meal-ingredient',
			text: 'After you have planned your meals, you can use the shopping list to track which ingredients you have or need to buy. Click one or more items to mark them as bought.',
			attachTo: {
				element: '.shopping-list',
				on: 'left'
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					waitForElement('.shopping-list').then(() => {
						debugLog('Found .shopping-list');
						resolve(true);
					});
				});
			},
			/* This isn't working for some reason, so have to add Next button */
			advanceOn: { selector: '.check-item', event: 'click' },
			buttons: [
				{
					text: 'Next',
					action: () => {
						return tour.next();
					}
				}
			],
			popperOptions: {
				modifiers: [{ name: 'offset', options: { offset: [10, 20] } }]
			}
		});

		tour.addStep({
			id: 'end',
			text: "That's it! If this looks useful to you, sign up for your own account!",
			classes: 'shepherd-end-extra',
			buttons: [
				{
					text: 'Leave',
					action: () => {
						tour.complete();
						auth.logout();
						return null;
					}
				},
				{
					text: 'Sign up',
					action: () => {
						tour.complete();
						goto('/signup');
					}
				},
				{
					text: 'Close',
					action: () => {
						tour.complete();
					}
				}
			]
		});
		// wait 1 second before starting the tour for the user to see the meal plan tab
		setTimeout(() => {
			debugLog('Starting demo tour');
			tour.start();
		}, 1000);
	});
</script>

{#if isDemo}
	<div class="shepherd-bar">
		{#if import.meta.env.DEV}
			<Button
				on:click={() => {
					tour && tour.back();
				}}>
				<SvgIcon mdiIcon={mdiArrowLeft} />
				Prev
			</Button>
		{/if}
		<p>Currently in Demo Mode!</p>
		{#if import.meta.env.DEV}
			<Button
				on:click={() => {
					tour && tour.next();
				}}>
				<SvgIcon mdiIcon={mdiArrowRight} />
				Next
			</Button>
		{/if}
	</div>
{/if}

<style>
	.shepherd-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background: #3e3e3e;
		color: white;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		padding: 10px;
		z-index: 9999;
	}
	.shepherd-bar p {
		line-height: 2;
	}
	:global(.shepherd-end-extra .shepherd-footer) {
		display: flex;
		justify-content: center;
	}
</style>
