<script lang="ts">
	import { onMount } from 'svelte';
	import { browser } from '$app/environment';
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';
	import { assets } from '$app/paths';
	import { fade } from 'svelte/transition';

	import type { AutoAdjustComponentDev, TopAppBarComponentDev } from '@smui/top-app-bar';
	import TopAppBar, { Row, Section, Title, AutoAdjust } from '@smui/top-app-bar';
	import IconButton from '@smui/icon-button';
	import Button, { Label } from '@smui/button';
	import Drawer, { AppContent, Content, Header, Subtitle, Scrim } from '@smui/drawer';
	import List, { Item, Text } from '@smui/list';
	import { mdiWeatherSunny, mdiWeatherNight, mdiLogout, mdiMenu, mdiShareVariant } from '@mdi/js';

	import SvgIcon from '$lib/SvgIcon.svelte';
	import auth from '$stores/authStore';
	import type { User, UserInfo } from 'firebase/auth';
	import userStore from '$stores/userStore';
	import Shepherd from './Shepherd.svelte';
	import { dateStore } from '$stores/dateStore';

	import { isDemoUser } from './utils';

	let topAppBar: TopAppBarComponentDev;
	let mobileDrawerOpen = false;
	let wrapperComponent: any = Content;
	$: inApp = $page.url.pathname.startsWith('/app');
	$: isLoginOrSignup =
		$page.url.pathname.startsWith('/login') || $page.url.pathname.startsWith('/signup');
	$: onInvitePage = $page.url.pathname.startsWith('/app/invite');
	$: isDemo = isDemoUser($userStore.user);

	$: {
		if ((inApp && !$auth.waitingForFirebase && !$auth.isLoggedIn) || (isDemo && onInvitePage)) {
			console.warn(`Unauthenticated access to /app not allowed, redirecting to login`);
			if (browser) {
				goto(`/`);
			}
		}
		/* AutoAdjust causes flickering of the hero content on the index page, so only use it inside the app */
		if (inApp || isLoginOrSignup) {
			wrapperComponent = AutoAdjust;
		}
	}
	let authIsReady: User | null | undefined;

	let swipingLeft = false;
	let swipingRight = false;
	let touchStartX = 0;
	let touchStartY = 0;
	let touchEndX = 0;
	let touchEndY = 0;
	function checkDirection() {
		if (touchEndX < touchStartX - 50 && Math.abs(touchEndY - touchStartY) < 25) {
			dateStore.adjustDate(7);
		}
		if (touchEndX > touchStartX + 50 && Math.abs(touchEndY - touchStartY) < 25) {
			dateStore.adjustDate(-7);
		}
	}

	function drawerNavigateTo(path: string) {
		mobileDrawerOpen = false;
		goto(path);
	}

	function drawerLogout() {
		mobileDrawerOpen = false;
		auth.logout();
	}

	onMount(async () => {
		authIsReady = await auth.waitForFirebase();
		document.addEventListener('touchstart', (e) => {
			touchStartX = e.changedTouches[0].screenX;
			touchStartY = e.changedTouches[0].screenY;
		});
		document.addEventListener('touchend', (e) => {
			touchEndX = e.changedTouches[0].screenX;
			touchEndY = e.changedTouches[0].screenY;
			swipingLeft = false;
			swipingRight = false;
			checkDirection();
		});
		document.addEventListener('touchmove', (e) => {
			let currentTouch = e.touches[0];
			if (touchStartX && touchStartY) {
				if (touchStartX < currentTouch.screenX - 25) {
					if (Math.abs(touchStartY - currentTouch.screenY) < 25) {
						swipingLeft = true;
					}
				} else if (touchStartX > currentTouch.screenX + 25) {
					if (Math.abs(touchStartY - currentTouch.screenY) < 25) {
						swipingRight = true;
					}
				}
			}
		});
	});
</script>

<TopAppBar bind:this={topAppBar} variant="fixed" aria-label="Top Navigation">
	<Row>
		<Section align="start" toolbar class="mobile">
			<IconButton
				aria-label="Menu"
				title="Menu"
				on:click={() => (mobileDrawerOpen = !mobileDrawerOpen)}>
				<SvgIcon mdiIcon={mdiMenu} />
			</IconButton>
		</Section>
		<Section>
			<Title>
				<div
					class="clickable-title"
					on:click={() => goto('/')}
					on:keydown={(e) => e?.key === 'Enter' && goto('/')}
					title="Go to the Landing page">
					<img src="{assets}/images/logo.png" alt="Logo" />
					Simple Weekly Meal Planner
				</div>
			</Title>
		</Section>

		<!-- Desktop Navigation -->
		{#if authIsReady !== undefined}
			<Section align="end" toolbar class="desktop" aria-label="Navigation Buttons">
				{#if $auth.isLoggedIn}
					{#if !inApp || onInvitePage}
						<Button on:click={() => goto('/app')}><Label>Go to App</Label></Button>
					{:else}
						{#if !isDemo}
							<div class="play-button">
								<Button
									variant="raised"
									on:click={() =>
										goto(
											'https://play.google.com/store/apps/details?id=com.fonner.simpleweeklymealplanner'
										)}
									class="mini"
									style="background-color: #000">
									<img
										src="{assets}/images/google-play.png"
										alt="Google Play"
										style="height: 42px" />
								</Button>
							</div>
							<div class="share-button">
								<Button
									color="secondary"
									variant="raised"
									on:click={() => goto('/app/invite')}
									class="mini">
									<SvgIcon mdiIcon={mdiShareVariant} />
									Invite Collaborators
								</Button>
							</div>
						{/if}
						{$auth.user?.displayName || $auth.user?.email}
						<IconButton aria-label="Logout" title="Logout" on:click={auth.logout}>
							<SvgIcon mdiIcon={mdiLogout} />
						</IconButton>
					{/if}
				{:else}
					<Button on:click={() => goto('/login')}><Label>Login</Label></Button>
					<Button on:click={() => goto('/signup')}><Label>Sign Up</Label></Button>
					<Button
						on:click={() => goto('/demo')}
						title="See a demonstration of the Simple Weekly Meal Planner"
						><Label>Demo</Label></Button>
				{/if}
			</Section>
		{/if}
	</Row>
</TopAppBar>
<svelte:component this={wrapperComponent} {topAppBar}>
	<!-- Mobile Navigation -->
	<Drawer variant="modal" fixed={true} bind:open={mobileDrawerOpen} class="mobile-drawer">
		<Content>
			<List>
				<Header>
					<Title class="drawer-header-title">Menu</Title>
				</Header>
				{#if $auth.isLoggedIn}
					{#if !inApp || onInvitePage}
						<Item on:click={() => drawerNavigateTo('/app')}><Text>Go to App</Text></Item>
					{/if}
					<!-- <Item on:click={darkMode.toggleDarkMode}
						><Text>{$darkMode ? 'Disable Dark Mode' : 'Enable Dark Mode'}</Text></Item> -->
					{#if !onInvitePage && !isDemo}
						<Item on:click={() => drawerNavigateTo('/app/invite')}>
							<Text>Invite Collaborators</Text>
						</Item>
					{/if}
					<Item on:click={drawerLogout}><Text>Logout</Text></Item>
				{:else}
					<Item on:click={() => drawerNavigateTo('/login')}><Text>Login</Text></Item>
					<Item on:click={() => drawerNavigateTo('/signup')}><Text>Sign Up</Text></Item>
					<Item on:click={() => drawerNavigateTo('/demo')}><Text>Demo</Text></Item>
				{/if}
				<Item
					on:click={() =>
						goto(
							'https://play.google.com/store/apps/details?id=com.fonner.simpleweeklymealplanner'
						)}><Text>Version {import.meta.env.VITE_APPVERSION}</Text></Item>
			</List>
		</Content>
	</Drawer>
	<Scrim fixed={true} />

	<AppContent class="app-content">
		{#if swipingLeft}
			<div class="swiping-left" transition:fade={{ duration: 150 }} />
		{/if}
		{#if swipingRight}
			<div class="swiping-right" transition:fade={{ duration: 150 }} />
		{/if}
		<div id="main" class="main-content" class:swipingLeft class:swipingRight>
			<slot />
		</div>
	</AppContent>
</svelte:component>

{#if $userStore.user}
	<Shepherd user={$userStore.user} />
{/if}

<style>
	:global(.button:focus) {
		text-decoration: underline;
		box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.25);
	}
	.clickable-title {
		cursor: pointer;
		display: flex;
		font-size: 1rem;
	}
	.clickable-title img {
		width: 30px;
		height: 30px;
	}
	.clickable-title:focus {
		border: 1px solid grey;
	}
	.swiping-left {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 20px;
		background: linear-gradient(to right, grey, transparent);
	}
	.swiping-right {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 20px;
		background: linear-gradient(to left, grey, transparent);
	}

	@media (min-width: 992px) {
		.clickable-title {
			font-size: 1.5rem;
		}
		.clickable-title img {
			width: 36px;
			height: 36px;
		}
	}
	.play-button {
		margin-right: 12px;
	}
	.share-button {
		margin-right: 18px;
	}
	:global(.drawer-header-title) {
		padding: 0;
	}
	:global(.mdc-drawer__header) {
		min-height: unset;
	}
	:global(.mini.mdc-button--raised) {
		font-size: 0.7rem;
		padding: 0 1em;
	}
	@media print {
		:global(.mobile) {
			display: none;
		}
		:global(.mdc-top-app-bar--fixed) {
			position: unset;
		}
		:global(.mdc-top-app-bar--fixed-adjust) {
			padding-top: 0;
		}
	}
</style>
